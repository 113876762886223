import './App.css';
import zoocchini from './assets/zoocchini.jpeg'
import fjk from './assets/FJK.jpeg'

function App() {
  return (

<div className="App">

{/* First clickable image */}
  <a href="http://www.zoocchiniwholesale.com" target="_blank" rel="noopener noreferrer">
    <img src={zoocchini} alt="zoochini link" />
  </a>

  {/* Second clickable image */}
  <a href="http://www.flapjackkidswholesale.com" target="_blank" rel="noopener noreferrer">
    <img src={fjk} alt="flap jack kids link" />
  </a>
</div>
)
}

export default App;
